import '../CompanyScorecard.css';

const CompanyScorecardPdf = (props) => {
  const {companyData} = props;
  return (
    <table className='company-details-table-pdf'>
      <thead>
        <tr>
          <th rowSpan={2}>Entity name</th>
          <th rowSpan={2}>Membership status</th>
          <th colSpan={5}>Selected Transparency Indicators</th>
          <th rowSpan={2}>Sector</th>
          <th rowSpan={2}>Entity Global HQ</th>
          <th rowSpan={2}>Entity Asia HQ</th>
        </tr>
        <tr>
          <th>Track % women in workforce by level</th>
          <th>Provide employee training or capacity building on gender issues</th>
          <th>Provide women-focused talent/ leadership program</th>
          <th>Provide parental leaves beyond statutory requirements</th>
          <th>Track any gender pay metrics</th>
        </tr>
      </thead>
      <tbody>
        {companyData && companyData.map(company => (
          <tr key={company.id}>
            <td style={{cursor:"pointer"}}>{company.data['0.1']}</td>
            <td>{company.membership}</td>
            <td className='green-tick'>✓</td>
            {company.data['12.1']?.[0] == 'Y' ? (
              <td className='green-tick'>✓</td>
            ) : (
              <td>✘</td>
            )}
            {company.data['12.2']?.[0] == 'Y' ? (
              <td className='green-tick'>✓</td>
            ) : (
              <td>✘</td>
            )}
            {company.data['4.1']?.[0] == 'Y' ? (
              <td className='green-tick'>✓</td>
            ) : (
              <td>✘</td>
            )}
            {(company.data['7.1']?.[0] == 'Y' && 
            company.data['7.1'] != 'Yes – We have an equal pay policy in place, but we currently do not track gender pay metrics') ? (
              <td className='green-tick'>✓</td>
            ) : (
              <td>✘</td>
            )}
            <td>{company.data['0.5']?.[0] || "N/A"}</td>
            <td>{company.data['0.7.a'] || "N/A"}</td>
            <td>{company.data['0.7.b'] || "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};

export default CompanyScorecardPdf;