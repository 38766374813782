import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import headerLogo from "../images/headerLogo.png";
import "../navigationBar.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import LanguageHashLink from "./LanguageHashLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

function NavigationBar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (languageCode) => {
    const pathname = window.location.pathname;
    const newPath = pathname.replace(`/${i18n.language}`, `/${languageCode}`);
    navigate(newPath);
    i18n.changeLanguage(languageCode);
  };

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(false); // Collapse navbar whenever location changes
  }, [location]);

  return (
    <Navbar fixed="top" expand="lg" className="navbar" expanded={expanded}>
      <Container>
        <Navbar.Brand as={LanguageHashLink} to="/">
          <img className="logo" src={headerLogo} alt="WWi Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <NavDropdown
              title={t("aboutUs.aboutUs")}
              id="about-us-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/about-us#vision-mission"
              >
                {t("aboutUs.visionAndMission")}
              </NavDropdown.Item>
              <NavDropdown.Item as={LanguageHashLink} to="/about-us#what-we-do">
                {t("aboutUs.whatWeDo")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/about-us#certification-and-index"
              >
                {t("aboutUs.certification")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={t("methodology.methodology")}
              id="methodology-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/methodology#assessment-methodology"
              >
                {t("methodology.assessmentMethodologyTitle")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/methodology#certification-process"
              >
                {t("methodology.certificationProcessTitle")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/methodology#pilot-research"
              >
                {t("methodology.pilotResearchTitle")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/methodology#company-findings"
              >
                {t("methodology.companyFindingsTitle")}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={LanguageHashLink}
                to="/methodology#monitor-2024"
              >
                {t("methodology.monitor2024Title")}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={LanguageHashLink} to="/company-dashboard">
              {t("dashboard.dashboard")}
            </Nav.Link>
            <Nav.Link as={LanguageHashLink} to="/join-us">
              {t("joinUs.joinUs")}
            </Nav.Link>
            <Nav.Link as={LanguageHashLink} to="/founders-message">
              {t("foundersMessage.title")}
            </Nav.Link>
            <Nav.Link as={LanguageHashLink} to="/supporting-organisations">
              {t("homepage.supportingOrganisationsTitle")}
            </Nav.Link>

            <NavDropdown
              title={t("news.news")}
              id="news-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item as={LanguageHashLink} to="/latest-news">
                {t("news.whatsNew")}
              </NavDropdown.Item>
              <NavDropdown.Item as={LanguageHashLink} to="/in-the-press">
                {t("news.inThePress")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={<FontAwesomeIcon icon={faLanguage} />}
              renderMenuOnMount={true}
            >
              <NavDropdown.Item onClick={() => handleLanguageChange("en")}>
                English
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleLanguageChange("zht")}>
                繁體中文
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleLanguageChange("zhs")}>
                简体中文
              </NavDropdown.Item>
              {/* <NavDropdown.Item onClick={() => handleLanguageChange("jp")}>
                日本語
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
