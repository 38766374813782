import React, { useState } from "react";
import "../home.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LanguageHashLink from "./LanguageHashLink";
import topRightImg from "../images/homeTopRight.webp";

import { ContactUs } from "./ContactUs";

import OrganisationLogos from "./OrganisationLogos";
import LaunchLogo2025 from "../images/launch2025.jpg";
import PressRelease2025Pdf from "../documents/Press release 2025.3.3.pdf";
import PressRelease2025PdfCN from "../documents/Press release CN 2025.3.3.pdf";

import { useTranslation } from "react-i18next";

export const Home = ({ id, currentTab }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isChineseLanguage =
    currentLanguage === "zht" || currentLanguage === "zhs";
  const pressReleasePdf = isChineseLanguage
    ? PressRelease2025PdfCN
    : PressRelease2025Pdf;

  const [showModal, setShowModal] = useState(true);

  return id === currentTab ? (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="home-modal-dialog"
      >
        <Modal.Body>
          <div className="launching-submodal flex flex-col sm:flex-row bg-white my-2">
            <div className="bg-white sm:my-0 sm:w-2/3">
              <div
                className="cross-icon-div"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <i className="fas fa-times text-[#760326] text-2xl cursor-pointer"></i>
              </div>
              <div
                className="launching-title"
                dangerouslySetInnerHTML={{ __html: t("launching.title") }}
              ></div>
              <br />
              <div className="launching-text">
                <p className="launching-subtitle">{t("launching.intro")}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("launching.researchDetails"),
                  }}
                ></p>
                <span>{t("launching.reportFurtherDetails")} </span>
                <a href={`${currentLanguage}/methodology#monitor-2024`} target="_blank">
                  {t("launching.here")}
                </a>
                <br />
                <span>{t("launching.pressRelease")} </span>
                <a href={pressReleasePdf} target="_blank">
                  {t("launching.here")}
                </a>
              </div>
            </div>
            <div className="sm:w-1/3">
              <img src={LaunchLogo2025} alt="launch2025" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <div className="imageGrid">
          <div className="imageRow top-row">
            <p className="overlay-text">{t("wwiTitle")}</p>
            <div className="image-container" id="top-left-img">
              {/* <img className="image" src={topLeftLogo} alt="logo" /> */}
            </div>
            <div className="image-container lady">
              <img className="image" src={topRightImg} alt="logo" />
            </div>
          </div>
          <div className="md:grid md:grid-cols-6">
            <div className="md:col-span-3 who-we-are">
              <p className="w-full">{t("homepage.whoWeAre")}</p>
            </div>
            <div className="md:col-span-3 text-content flex">
              <div className="self-center">
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("homepage.intro"),
                    }}
                  ></p>
                </div>
                <div className="text-center">
                  <LanguageHashLink to="about-us">
                    <Button className="primary-button">{t("readMore")}</Button>
                  </LanguageHashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <div className="mt-6 supporting-organisations-title">
            {t("homepage.supportingOrganisationsTitle")}
          </div> */}
          <OrganisationLogos />
        </div>
        <ContactUs />
      </div>
    </>
  ) : null;
};
