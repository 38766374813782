import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";

const ColumnFilter = ({ 
  column, 
  options, 
  selectedValue, 
  onFilterChange,
  label 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  const selectedValues = Array.isArray(selectedValue) ? selectedValue : (selectedValue ? [selectedValue] : []);

  // Group options by label
  const groupedOptions = options.reduce((acc, option) => {
    const label = typeof option === 'object' ? option.label : option;
    const value = typeof option === 'object' ? option.value : option;
    
    if (!acc[label]) {
      acc[label] = {
        label,
        values: [value]
      };
    } else {
      acc[label].values.push(value);
    }
    return acc;
  }, {});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (values) => {
    let newValues = [...selectedValues];
    
    // Check if all values for this label are already selected
    const allSelected = values.every(value => selectedValues.includes(value));
    
    if (allSelected) {
      // Remove all values for this label
      newValues = newValues.filter(val => !values.includes(val));
    } else {
      // Add any missing values for this label
      values.forEach(value => {
        if (!newValues.includes(value)) {
          newValues.push(value);
        }
      });
    }
    
    onFilterChange(column, newValues.length ? newValues : null);
  };

  const getDisplayText = () => {
    if (!selectedValues.length) return 'All';
    
    // Count unique labels that are fully selected
    const selectedLabels = Object.values(groupedOptions).filter(group => 
      group.values.every(value => selectedValues.includes(value))
    ).map(group => group.label);
    
    if (selectedLabels.length === 1) return selectedLabels[0];
    return `${selectedLabels.length} selected`;
  };

  return (
    <div className="column-filter" ref={dropdownRef}>
      <div 
        className="filter-header" 
        onClick={() => setIsOpen(!isOpen)}
      >
        {label} <br />
        <div className="filter-selected">
          {getDisplayText()}
          <FontAwesomeIcon 
            icon={faChevronDown} 
            className={`filter-icon ${isOpen ? 'open' : ''}`}
          />
        </div>
      </div>
      {isOpen && (
        <div className="filter-dropdown">
          <div 
            className={`filter-option ${!selectedValues.length ? 'selected' : ''}`}
            onClick={() => onFilterChange(column, null)}
          >
            <span className="filter-option-text">All</span>
            {!selectedValues.length && (
              <FontAwesomeIcon icon={faCheck} className="filter-check" />
            )}
          </div>
          {Object.values(groupedOptions).map((group, index) => {
            const isSelected = group.values.every(value => 
              selectedValues.includes(value)
            );
            return (
              <div
                key={index}
                className={`filter-option ${isSelected ? 'selected' : ''}`}
                onClick={() => handleOptionClick(group.values)}
              >
                <span className="filter-option-text">{group.label}</span>
                {isSelected && (
                  <FontAwesomeIcon icon={faCheck} className="filter-check" />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ColumnFilter; 