import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import axios from "axios";
import "../CompanyScorecard.css";
import "../CompanyProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "../images/search-icon.svg";
import goldBadge from "../images/gold_badge.png";
import basicBadge from "../images/basic_badge.png";
import CompanyProfile from "./CompanyProfile";
import LoadingSpinner from "./LoadingSpinner";
import CompanyScorecardPdf from "./CompanyScorecardPdf";
import ColumnFilter from "./ColumnFilter";

const SECTOR_VALUES = [
  "Utilities",
  "Financials",
  "Real Estate",
  "Education",
  "Commercial & Professional Services",
  "Communication And Media Services",
  "Energy",
  "Pharmaceuticals, Healthcare Equipment & Services",
  "Information Technology",
  "Transportation",
  "Retailing",
  "Materials",
  "Automobile & Components",
  "Consumer Services & Retailing",
  "Construction & Capital Goods",
];
const STOCK_EXCHANGE_VALUES = [
  { value: "HKEX", label: "Hong Kong" },
  { value: "SET", label: "Thailand" },
  { value: "BSE", label: "India" },
  { value: "KLSE", label: "Malaysia" },
  { value: "SGX", label: "Singapore" },
  { value: "KRX", label: "Korea" },
  { value: "TSE", label: "Japan" },
  { value: "NSE", label: "India" },
  { value: "NYSE", label: "USA" },
];

const CompanyScorecard = () => {
  const { t } = useTranslation();
  const [showCompanyProfile, setShowCompanyProfile] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [companyDataDuplicate, setCompanyDataDuplicate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [stockCode, setstockCode] = useState("");
  const itemsPerPage = 10;
  const [sortBy, setSortBy] = useState(null);
  const [subsortBy, setSubSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [timestamp, setTimestamp] = useState(null);
  const [filters, setFilters] = useState({});

  const handleSort = (columnName, subColumnName = null) => {
    setCurrentPage(1);
    if (sortBy === columnName) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
        setSortBy(null);
        setSubSortBy(null);
      }
    } else {
      setSubSortBy(subColumnName);
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  const handleTimestamp = () => {
    if (companyDataDuplicate.length > 0) {
      const maxTimestamp = Math.max(
        ...companyDataDuplicate.map((obj) => obj.timestamp)
      );

      const timestampInMilliseconds = maxTimestamp * 1000;
      // Create a new Date object from the timestamp
      const dateObject = new Date(timestampInMilliseconds);

      // Format the date as "Month, Year" using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(dateObject);

      setTimestamp(formattedDate);
    }
  };

  useEffect(() => {
    window.gtag("config", "G-KWFX6KEKJY", {
      page_path: "company-dashboard",
    });

    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    // Creating the params object to include both searchQuery and stock_code
    const params = {
      name: searchQuery || undefined, //  for company name search
      stock_code: stockCode || undefined, // for stock code search
    };

    // Passing the params to the API call
    axios
      .post(
        "https://wwi-questionnaire-v2.azurewebsites.net/api/getCompanyList",
        config
      )
      .then((response) => {
        // const goldMembers = response.data?.list.filter(item => item.membership === 'GOLD');
        setCompanyData(response.data?.list);
        setCompanyDataDuplicate(response.data?.list);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoading(false);
      });
  }, []);

  const handleSearchQuery = () => {
    if (!searchQuery && !stockCode && !filters) {
      // If search query is empty, reset to the original data
      setCompanyData(companyDataDuplicate);
    } else {
      // Convert the search query to lowercase for case-insensitive comparison
      const lowerCaseQuery = searchQuery.toLowerCase();
      const lowerCaseStockCode = stockCode.toLowerCase();

      // Filter based on both name and stock code
      const filteredData = companyDataDuplicate.filter((company) => {
        const companyName =
          company.data["0.1"]
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase() || "";
        const companyStockCode = company.data["0.3.b"]?.toString() || "";
        const matchesFilters = Object.entries(filters).every(
          ([column, values]) => {
            if (!values || !values.length) return true;
            switch (column) {
              case "membership":
                return values.includes(company.membership);
              case "0.5":
                return values.includes(company.data["0.5"]?.[0]); // for sector
              case "0.3.a":
                return values.includes(company.data["0.3.a"]); // for stock exchange
              default:
                return values.includes(company.data[column]);
            }
          }
        );
        return (
          (companyName.includes(lowerCaseQuery) ||
            companyStockCode.includes(lowerCaseStockCode)) &&
          matchesFilters
        );
      });

      setCompanyData(filteredData); // Updating the companyData with the filtered result
      setCurrentPage(1); // Reset to the first page after filtering
    }
  };

  useEffect(() => {
    handleTimestamp();
  }, [companyDataDuplicate]);

  useEffect(() => {
    handleSearchQuery();
  }, [searchQuery, filters]);

  const handleBackArrowClick = () => {
    setShowCompanyProfile(!showCompanyProfile);
    setCompanyId(null);
  };

  const handleCompanyClick = (companyId) => {
    window.gtag("event", "company-profile-click", {
      "company-id": companyId,
    });
    setCompanyId(companyId);
    setShowCompanyProfile(!showCompanyProfile);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedItems = companyData.slice().sort((a, b) => {
    if (sortBy && sortBy != "dummy") {
      let aValue = "";
      let bValue = "";
      if (subsortBy) {
        aValue = a.data[sortBy]?.[subsortBy] || "";
        bValue = b.data[sortBy]?.[subsortBy] || "";
      } else {
        if (a?.data?.[sortBy]) {
          aValue = a.data[sortBy] || "";
          bValue = b.data[sortBy] || "";
        } else {
          aValue = a[sortBy] || "";
          bValue = b[sortBy] || "";
        }
      }

      return sortOrder === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    return 0;
  });

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDownloadClick = () => {
    const printContent = <CompanyScorecardPdf companyData={companyData} />;
    const printHtml = ReactDOMServer.renderToString(printContent);
    const container = document.createElement("div");
    container.innerHTML = printHtml;

    const options = {
      margin: [10, 0, 10, 0],
      filename: "company-list",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    };
    html2pdf(container, options);
  };

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  return loading ? (
    <LoadingSpinner />
  ) : !showCompanyProfile ? (
    <div className="company-scorecard">
      <div className="header-image-container">
        <div className="image">
          <p className="main-heading">Company Dashboard</p>
        </div>
      </div>
      <div className="company-scorecard-content">
        <div className="certification-status-update">
          <p className="cert-header">
            Certification status update:{" "}
            <span className="cert-header-date">{timestamp}</span>
          </p>
          <div className="cert-line" />
          <div className="cert-text">
            <p
              dangerouslySetInnerHTML={{
                __html: t("dashboard.dashboardDesc"),
              }}
            ></p>
          </div>
        </div>
        <div className="cert-search">
          <input
            type="text"
            placeholder="Search by name or stock code"
            className="mx-2 cert-search-text h-full w-full"
            value={searchQuery}
            onChange={(e) => {
              const query = e.target.value;
              setSearchQuery(query);
              setstockCode(query); // You could check if the query is a valid stock code pattern here
            }}
          />
          <img src={SearchIcon} alt="search" className="cert-search-icon"></img>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="cert-download" onClick={handleDownloadClick}>
            Download
          </div>
        </div>
      </div>
      <div className="company-details-table-container">
        <table className="company-details-table">
          <thead>
            <tr>
              <th rowSpan={2}>Entity name</th>
              <th rowSpan={2}>
                <ColumnFilter
                  column="membership"
                  options={["GOLD", "BASIC", "IN-SCOPE"]}
                  selectedValue={filters.membership}
                  onFilterChange={handleFilterChange}
                  label="Membership"
                />
              </th>
              <th rowSpan={2}>
                <ColumnFilter
                  column="0.3.a"
                  options={STOCK_EXCHANGE_VALUES}
                  selectedValue={filters["0.3.a"]}
                  onFilterChange={handleFilterChange}
                  label="Stock exchange"
                />
              </th>
              <th
                rowSpan={2}
                onClick={() => handleSort("0.3.b", "0")}
                style={{ cursor: "pointer" }}
              >
                Stock code <br />
                <br />
                {sortBy === "0.3.b" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
              <th colSpan={5}>Selected Transparency Indicators</th>
              <th rowSpan={2} style={{ cursor: "pointer" }}>
                <ColumnFilter
                  column="0.5"
                  options={SECTOR_VALUES}
                  selectedValue={filters["0.5"]}
                  onFilterChange={handleFilterChange}
                  label="Sector"
                />
              </th>
              <th
                rowSpan={2}
                onClick={() => handleSort("0.7.a")}
                style={{ cursor: "pointer" }}
              >
                Entity Global HQ <br />
                <br />
                {sortBy === "0.7.a" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
              <th
                rowSpan={2}
                onClick={() => handleSort("0.7.b")}
                style={{ cursor: "pointer" }}
              >
                Entity Asia HQ <br />
                <br />
                {sortBy === "0.7.b" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
            </tr>
            <tr>
              <th
                onClick={() => handleSort("dummy")}
                style={{ cursor: "pointer" }}
              >
                Track % women in workforce by level <br />
                <br />
                {sortBy === "dummy" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
              <th
                onClick={() => handleSort("12.1", "0")}
                style={{ cursor: "pointer" }}
              >
                Provide employee training or capacity building on gender issues{" "}
                <br />
                <br />
                {sortBy === "12.1" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
              <th
                onClick={() => handleSort("12.2", "0")}
                style={{ cursor: "pointer" }}
              >
                Provide women-focused talent/leadership program <br />
                <br />
                {sortBy === "12.2" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
              <th
                onClick={() => handleSort("4.1", "0")}
                style={{ cursor: "pointer" }}
              >
                Provide parental leaves beyond statutory requirements <br />
                <br />
                {sortBy === "4.1" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
              <th
                onClick={() => handleSort("7.1", "0")}
                style={{ cursor: "pointer" }}
              >
                Track any gender pay metrics <br />
                <br />
                {sortBy === "7.1" ? (
                  sortOrder === "asc" ? (
                    "▲"
                  ) : (
                    "▼"
                  )
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((company) => (
                <tr key={company.id}>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCompanyClick(company.id)}
                  >
                    {company.data["0.1"]}
                  </td>
                  <td>
                    {(() => {
                      switch (company.membership) {
                        case "GOLD":
                          return (
                            <img
                              src={goldBadge}
                              alt="gold"
                              className="badge-image"
                            />
                          );
                        case "BASIC":
                          return (
                            <img
                              src={basicBadge}
                              alt="basic"
                              className="badge-image"
                            />
                          );
                        default:
                          return <div>IN-SCOPE</div>;
                      }
                    })()}
                  </td>
                  <td>{company.data["0.3.a"]}</td>
                  <td>{company.data["0.3.b"]}</td>
                  <td className="green-tick">✓</td>
                  {company.data["12.1"]?.[0] == "Y" ? (
                    <td className="green-tick">✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  {company.data["12.2"]?.[0] == "Y" ? (
                    <td className="green-tick">✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  {company.data["4.1"]?.[0] == "Y" ? (
                    <td className="green-tick">✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  {company.data["7.1"]?.[0] == "Y" &&
                  company.data["7.1"] !=
                    "Yes – We have an equal pay policy in place, but we currently do not track gender pay metrics" ? (
                    <td className="green-tick">✓</td>
                  ) : (
                    <td>✘</td>
                  )}
                  <td>
                    {company.data["0.5"]?.[0] === "Others, please specify"
                      ? company.data["0.5.a"]
                      : company.data["0.5"]?.[0] || "N/A"}
                  </td>
                  <td>{company.data["0.7.a"] || "N/A"}</td>
                  <td>{company.data["0.7.b"] || "N/A"}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="pagination">
          <span className="mr-2">Page</span>
          {Array.from({
            length: Math.ceil(companyData.length / itemsPerPage),
          }).map((_, index) => (
            <span
              key={index}
              onClick={() => paginate(index + 1)}
              className={
                currentPage === index + 1
                  ? "active-page mx-2"
                  : "" + "mx-2 inactive-page"
              }
            >
              {index + 1}
            </span>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <CompanyProfile
      companyId={companyId}
      handleBackArrowClick={handleBackArrowClick}
    />
  );
};

export default CompanyScorecard;
