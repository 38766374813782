import { useTranslation } from "react-i18next";
import "../ResearchAndMethodology.css";

import { Collapse } from "react-collapse";

import downIcon from "../images/down-arrow.png";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import InScopeCompaniesPdf from "../documents/WWi In-scope companies_Asia_20250302.pdf";
import InScopeCompaniesPdfHK from "../documents/WWi In-scope companies_HK_20250302.pdf";
import CertificationImg from "../images/Certification_side.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ChineseMonitor2024Pdf from "../documents/Women Workplace Monitor 2024 CN.pdf";
import EnglishMonitor2024Pdf from "../documents/Women Workplace Monitor 2024.pdf";

const ResearchAndMethodology = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isChineseLanguage = currentLanguage === 'zht' || currentLanguage === 'zhs';

  const monitor2024Url = isChineseLanguage ? ChineseMonitor2024Pdf : EnglishMonitor2024Pdf;

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const toggleCollapseSection = (sectionNumber) => {
    switch (sectionNumber) {
      case 1:
        setShow1(!show1);
        break;
      case 2:
        setShow2(!show2);
        break;
      case 3:
        setShow3(!show3);
        break;
      case 4:
        setShow4(!show4);
        break;
      case 5:
        setShow5(!show5);
        break;
      default:
        break;
    }
  };

  const handleTitleClick = (sectionNumber) => {
    toggleCollapseSection(sectionNumber);
  };

  useEffect(() => {
    const hash = location.hash.substring(1);
    switch (hash) {
      case "assessment-methodology":
        setShow1(true);
        break;
      case "certification-process":
        setShow2(true);
        break;
      case "pilot-research":
        setShow3(true);
        break;
      case "company-findings":
        setShow4(true);
        break;
      case "monitor-2024":
        setShow5(true);
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <div className="research-and-methodology">
      <div className="header-image-container">
        <div className="header">
          <p>{t("methodology.headerTitle")}</p>
        </div>
      </div>
      <div className="content">
        <div className="sub-sections">
          <div className="text-section">
            <div
              id="assessment-methodology"
              className={`title-section ${show1 ? "font-bold" : ""}`}
              onClick={() => handleTitleClick(1)}
            >
              <div>
                <p className="title-paragraphs">
                  {t("methodology.assessmentMethodologyTitle")}
                </p>
              </div>
              <img
                style={{ transform: !show1 ? `rotate(-90deg)` : "" }}
                className="arrow"
                src={downIcon}
                alt="logo"
              />
            </div>
            <Collapse isOpened={show1}>
              <div className="description text-center">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("methodology.assessmentMethodologyDesc"),
                  }}
                ></p>
              </div>
              <div className="methodology-house">
                <div className="methodology-pillar-triangle"></div>
                <div className="methodology-base"></div>
                <Row className="methodology-pillar-row">
                  <Col sm={12} md={4} className="methodology-pillar-container">
                    <div className="methodology-pillar">
                      <p className="title">
                        {t("methodology.assessmentMethodologyPillar.title1")}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "methodology.assessmentMethodologyPillar.list1"
                          ),
                        }}
                      ></p>
                    </div>
                  </Col>
                  <Col sm={12} md={4} className="methodology-pillar-container">
                    <div className="methodology-pillar">
                      <p className="title">
                        {t("methodology.assessmentMethodologyPillar.title2")}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "methodology.assessmentMethodologyPillar.list2"
                          ),
                        }}
                      ></p>
                    </div>
                  </Col>
                  <Col sm={12} md={4} className="methodology-pillar-container">
                    <div className="methodology-pillar">
                      <p className="title">
                        {t("methodology.assessmentMethodologyPillar.title3")}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "methodology.assessmentMethodologyPillar.list3"
                          ),
                        }}
                      ></p>
                    </div>
                  </Col>
                </Row>
                <div className="methodology-base"></div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="sub-sections">
          <div className="text-section">
            <div
              id="certification-process"
              className={`title-section ${show2 ? "font-bold" : ""}`}
              onClick={() => handleTitleClick(2)}
            >
              <div>
                <p className="title-paragraphs">
                  {t("methodology.certificationProcessTitle")}
                </p>
              </div>
              <img
                style={{ transform: !show2 ? `rotate(-90deg)` : "" }}
                className="arrow"
                src={downIcon}
                alt="logo"
              />
            </div>
            <Collapse isOpened={show2}>
              <div id="certification-illustration">
                <Row gutter={16}>
                  <Col md={3}>
                    <img
                      src={CertificationImg}
                      id="certification-img"
                      alt="Certification Process"
                    />
                  </Col>
                  <Col md={9} className="column">
                    <Row className="section">
                      <Col md={3}>
                        <div className="title">{t("methodology.certificationProcess.assessmentFramework")}</div>
                      </Col>
                      <Col md={9} className="text">
                        <div>{t("methodology.certificationProcess.questionnaireDescription")}</div>
                        <div>{t("methodology.certificationProcess.questionsDesigned")}</div>
                      </Col>
                    </Row>
                    <Row className="section">
                      <Col md={3}>
                        <div className="title">{t("methodology.certificationProcess.scopeOfCoverage")}</div>
                      </Col>
                      <Col md={9} className="text">
                        <div>{t("methodology.certificationProcess.coverageDescription")}</div>
                        <div>{t("methodology.certificationProcess.asianCoverage")}</div>
                      </Col>
                    </Row>
                    <Row className="section">
                      <Col md={3}>
                        <div className="title">
                          {t("methodology.certificationProcess.entityLevelDataCollection")}
                        </div>
                      </Col>
                      <Col md={9} className="text">
                        <div>{t("methodology.certificationProcess.dataSource")}</div>
                        <div>{t("methodology.certificationProcess.dataCollectionAndAnalysis")}</div>
                      </Col>
                    </Row>
                    <Row className="section">
                      <Col md={3}>
                        <div className="title">{t("methodology.certificationProcess.dataAnalysis")}</div>
                      </Col>
                      <Col md={9} className="text">
                        <div>{t("methodology.certificationProcess.transparency")}</div>
                        <div>{t("methodology.certificationProcess.management")}</div>
                        <div>{t("methodology.certificationProcess.target")}</div>
                        <div>{t("methodology.certificationProcess.performance")}</div>
                      </Col>
                    </Row>
                    <Row className="section">
                      <Col md={3}>
                        <div className="title">
                          {t("methodology.certificationProcess.certificationBenchmarkingAndPublicity")}
                        </div>
                      </Col>
                      <Col md={9} className="text">
                        <div>{t("methodology.certificationProcess.scoreAllocation")}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="sub-sections">
          <div className="text-section">
            <div
              id="pilot-research"
              className={`title-section ${show3 ? "font-bold" : ""}`}
              onClick={() => handleTitleClick(3)}
            >
              <div>
                <p className="title-paragraphs">
                  {t("methodology.pilotResearchTitle")}
                </p>
              </div>
              <img
                style={{ transform: !show3 ? `rotate(-90deg)` : "" }}
                className="arrow"
                src={downIcon}
                alt="logo"
              />
            </div>
            <Collapse isOpened={show3}>
              <div className="description">
                <div className="pilot-desc">
                  {t("methodology.pilotResearchDesc")}
                </div>
                <div className="pilot-container">
                  <div className="row">
                    <div className="col-sm-2">
                      <i className="fas fa-clipboard pilot-icons"></i>
                    </div>
                    <div className="col-sm-10 pilot-text">
                      <span>
                        {t(
                          "methodology.pilotResearchActivities.testQuestionnaire"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <i className="fas fa-venus-mars pilot-icons"></i>
                    </div>
                    <div className="col-sm-10 pilot-text">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "methodology.pilotResearchActivities.assessGenderData"
                          ),
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <i className="fas fa-balance-scale pilot-icons"></i>
                    </div>
                    <div className="col-sm-10 pilot-text">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "methodology.pilotResearchActivities.compareMarkets"
                          ),
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <i className="fas fa-users pilot-icons"></i>
                    </div>
                    <div className="col-sm-10 pilot-text">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "methodology.pilotResearchActivities.engageStakeholders"
                          ),
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="pilot-desc">
                  <p>{t("methodology.pilotResearchOutcome")}</p>
                  <p>{t("methodology.pilotResearchSummary")}</p>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="sub-sections">
          <div className="text-section">
            <div
              id="company-findings"
              className={`title-section ${show4 ? "font-bold" : ""}`}
              onClick={() => handleTitleClick(4)}
            >
              <div>
                <p className="title-paragraphs">
                  {t("methodology.companyFindingsTitle")}
                </p>
              </div>
              <img
                style={{ transform: !show4 ? `rotate(-90deg)` : "" }}
                className="arrow"
                src={downIcon}
                alt="logo"
              />
            </div>
            <Collapse isOpened={show4}>
              <div className="description">
                <div style={{ marginTop: "30px" }} className="text-left">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("methodology.companyFindingsDesc"),
                    }}
                  ></p>
                  <br />
                  <br />
                  <span>{t("methodology.companyFindingsClick")} </span>
                  <a href={InScopeCompaniesPdf} target="_blank">
                    {t("methodology.companyFindingsHere")}
                  </a>
                  <span> {t("methodology.companyFindingsDownload")}</span>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="sub-sections">
          <div className="text-section">
            <div
              id="monitor-2024"
              className={`title-section ${show5 ? "font-bold" : ""}`}
              onClick={() => handleTitleClick(5)}
            >
              <div>
                <p className="title-paragraphs">
                  {t("methodology.monitor2024Title")}
                </p>
              </div>
              <img
                style={{ transform: !show5 ? `rotate(-90deg)` : "" }}
                className="arrow"
                src={downIcon}
                alt="logo"
              />
            </div>
            <Collapse isOpened={show5}>
              <div className="description">
                <div style={{ marginTop: "30px" }} className="text-left">
                  <p><b>{t("methodology.monitor2024Subtitle")}</b></p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("methodology.monitor2024Desc"),
                    }}
                  ></p>
                  <span>{t("methodology.monitor2024Click")} </span>
                  <a href={monitor2024Url} target="_blank">
                    {t("methodology.monitor2024Here")}
                  </a>
                  <br />
                  <span>{t("methodology.coveredCompanies")} </span>
                  <a href={InScopeCompaniesPdfHK} target="_blank">
                    {t("methodology.coveredCompaniesHere")}
                  </a>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchAndMethodology;
